body {
    overflow-x: hidden;
}

.custom-bg-color {
    background-color: #333;
}

.contact-section {
    width: 100%;
    margin-top: 200px;
    margin-bottom: 200px;
    height: 400px;
    background: linear-gradient(to right, #333, gray);
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    text-align: center;
    border-radius: 30px;

}

.input-field {
    width: 100%;
    height: 40px;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}

.submit-button {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.marker {
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 640px) {
    .contact-section {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .contact-section {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .contact-section {
        max-width: 1024px;
    }
}

@media (max-width: 767px) {
    .contact-section {
        height: auto;
        max-width: 80%;
        margin-bottom: 30px;
    }

    .contact-section form {
        margin-bottom: 50px;
    }
}