.footer {
    background-color: #f2f2f2;
    color: #555;
    padding: 20px;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.footer-column {
    flex: 1 1 100%;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .footer-column {
        flex: 0 1 auto;
        max-width: 50%;
    }
}

.footer-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    /* Added to center the logo */
}

.footer-heading img {
    max-width: 100%;
    /* Updated to use the full width available */
    height: auto;
    align-items: first baseline;
}


.footer-text {
    font-size: 14px;
    margin-bottom: 20px;
}

.footer-bottom {
    background-color: #555;
    padding: 10px;
    text-align: center;
}

.footer-bottom .footer-text {
    color: #fff;
    font-size: 12px;
}

.smaller-image {
    max-width: 70%;
    max-height: 200px;
}