@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");



.navbar {
  background-color: #f8f8f8;
  padding: 5px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 12%;
  z-index: 100;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.menu-icon {
  display: none;
}

.navbar__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
}

.navbar__logo {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  padding-top: 10px;
}

.navbar__menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar__item {
  margin-right: 20px;
  font-size: 14px;
  padding-top: 10px;
}

.navbar__link {
  color: #333;
  text-decoration: none;
  padding: 5px 5px;
  border-radius: 5px;
  font-size: medium;
  font-weight: normal;
  font-size: 16px;
}

.navbar__link.active {
  font-weight: bold;
}

.navbar__link:hover {
  background-color: #f8f8f8;
  font-weight: bold;
}

/* Hide the navbar when scrolling down */
.navbar--hidden {
  transform: translateY(-100%);
}

.navbar__logo-image {
  width: 100%;
  /* Adjust the width as needed */
  max-width: 150px;
  /* Adjust the max-width as needed */
  height: auto;
}

/* Responsive styles */
@media (max-width: 768px) {

  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

  .navbar {
    height: 20%;
    /* Adjust the height as needed */
    padding: 5px;
    position: fixed;
    /* Add position: fixed to keep the navbar fixed */
    transform: translateY(0);
    /* Add transform: translateY(0) to reset the transformation */
  }

  .navbar__container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .navbar__logo {
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 5px;
    padding-top: 3px;
    /* Adjust the padding as needed */
  }

  .navbar__menu {
    flex: 1 1 100%;
    justify-content: center;
    margin-top: 10px;
    /* Add margin to push navbar links down */
  }

  .navbar__item {
    margin: 5px;
  }

  .navbar--hidden .navbar__menu {
    display: none;
  }

  .navbar--hidden {
    transform: translateY(-100%);
  }
}



@media (max-width: 600px) {
  .navbar {
    height: 85px;
  }

  .navbar__logo .logo-container {
    display: flex;
    align-items: center;
  }

  .navbar__logo {
    display: flex;
    justify-content: space-between;
    /* Aligns content to the left and right */
    align-items: center;
    /* Centers vertically */
    padding-bottom: 17px;
  }

  .navbar__logo-image {
    padding-top: 25px;
    min-width: 120px;
    width: 120px;
    margin: 0 50%;
    /* Centers the image horizontally */
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    margin-right: 10px;
    /* Adjust margin as needed */
    transform: rotate(180deg);
  }

  .nav-elements {
    position: absolute;
    left: 0;
    top: 85px;
    background-color: #f8f8f8;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 300px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    text-decoration: underline;
    padding: 3px;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}