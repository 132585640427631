body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.chairs-container {
    overflow-x: hidden;
}

.chairs-products-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 100px;
}

button.selected {
    background-color: black;
    color: white;
}

.chairs-product-text {
    padding: 60px;
    background-color: #675656;
    color: white;
    text-align: center;
    align-items: center;

}

.chairs-content-container {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
}

.chairs-sidebar p {
    font-weight: bold;
}

.chairs-sidebar {
    width: 100%;
    max-width: 100%;
    background-color: #f2f2f2;
    padding: 10px;
    float: left;
}

.chairs-sidebar-button {
    background-color: #675656;
    color: white;
    margin: 8px;
    width: max-content;
    padding: 3px;
    border-radius: 10px;
}

.chairs-grid-option {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    /* Add additional styles if needed */
}

.chairs-grid-option-button {
    padding: 5px 10px;
    margin-left: 5px;
    background-color: #eee;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chairs-grid-option-button.active {
    background-color: #ccc;
}

.chairs-product-list {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin: 20px;
}

.chairs-product-list.grid-3 {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.chairs-product-list.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.chairs-product-image {
    transition: transform 0.3s;
}

.chairs-product-image:hover {
    transform: scale(1.05);
}

.chairs-product-image img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    border-radius: 20px;
}

.chairs-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    cursor: pointer;
    backdrop-filter: blur(10px);
}

.chairs-popup {
    max-width: 90%;
    max-height: 100%;
    background-color: white;
    border-radius: 8px;
    position: relative;
}

.chairs-popup-image {
    max-width: 100%;
    max-height: 70vh;
    /* Adjust the value as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.chairs-popup-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.chairs-popup-nav {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    display: flex;
    justify-content: space-between;
}

.chairs-popup-nav-button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.chairs-popup-nav-button:hover {
    background-color: #555;
}

.color-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.color-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 3px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: gray;
    padding: 0;
}

.color-button.active {
    background-color: #007bff;
    /* or any other active color */
}


@media (max-width: 768px) {
    .chairs-mainCategory-button img {
        width: 90px;
        /* Adjust the width as needed for mobile */
        margin-bottom: 2px;
        /* Reduce the margin for mobile */
    }

    .chairs-popup-nav-button {
        padding: 8px 16px;
        font-size: 14px;
        background-color: #333;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .chairs-subTableCategory-button img {
        width: 90px;
        /* Adjust the width as needed for mobile */
        margin-bottom: 2px;
        /* Reduce the margin for mobile */
    }

    .chairs-products-page {
        margin-top: 110px;
    }

    .chairs-product-list.grid-3 {
        flex-grow: 1;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }

    .chairs-product-list.grid-4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
}