/* Default styles */

.body {
    background-color: white !important;
}

.Home {
    text-align: center;
}

.App {
    overflow-x: hidden;
}

.wrapper-chair {
    width: 90%;
    height: 200px;
    border-radius: 10px;
    padding-top: 40px;
    margin-top: 40px;
}

.Home-Text-Panel-container-chair {
    border-radius: 10px;
    height: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home-logo {
    height: 40vmin;
    pointer-events: none;
}

.Home-Main-Header {
    width: 100%;
    height: 100px;
    background-color: #61dafb;
    color: #61dafb;
}

.Home-Text-Panel-container h1 {
    font-size: 16px;
}

h1 {
    font-size: 16px;
    color: black;
}

.arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.arrow-icon.left {
    left: 20px;
}

.arrow-icon.right {
    right: 20px;
}

.Home-Text-Panel-container {
    width: 100%;
    border-color: rgb(153, 145, 145);
    text-align: left;
    padding-left: 50px;
    background-color: #333;
    border-radius: 4px;
}

.Home-Text-Panel-container h1 {
    color: white;
    padding-right: 20px;
    text-align: left;
}

.Home-Text-Panel-container h2 {
    color: rgb(145, 133, 133);
    padding-right: 20px;
    text-align: left;
}

.wrapper {
    width: 100%;
    display: flex;
    border-radius: 30px;
    border-color: black;
    max-width: 100%;
    padding-top: 80px;
}

.Home-product-list::-webkit-scrollbar {
    display: none;
}

.Home-product-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    margin-bottom: -10px;
    padding-bottom: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.Home-product-image {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 25%;
    padding-left: 20px;
    border-radius: 20px;
}

.Home-product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.Home-product-image h2 {
    font-size: 18px;
}

.Home-product-image p {
    font-size: 14px;
    color: gray;
}

.Home-App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Home-App-link {
    color: #61dafb;
}

@keyframes Home-App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.Home-image-view {
    width: 100%;
    height: calc(100vh - 1000px);
    overflow-x: auto;
    overflow-y: hidden;
}

.Home-image-view__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 30px);
}

.Home-image-view__image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.Home-section-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    font-size: 13px;
    color: #f2f2f2;
}

.Home-section-buttons button {
    background-color: #ccc;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
    width: 120px;
    color: white;
    font-weight: bold;
}

.Home-section-buttons button.active {
    background-color: #928989;
}

.Home-section-content {
    text-align: center;
    max-width: 100%;
}

.Home-section-content p {
    font-size: 18px;
    margin-top: 20px;
}

.Home-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    backdrop-filter: blur(10px);
    /* Apply backdrop blur effect */
}


.Home-popup {
    max-width: 70%;
    max-height: 70%;
    background-color: white;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    /* ... Other styles ... */

    .Home-popup-overlay {
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        /* Apply backdrop blur effect */
    }

    .Home-popup {
        max-width: 90%;
        /* Adjust the maximum width as needed */
        max-height: 100%;
        /* Adjust the maximum height as needed */
    }
}

.Home-popup img {
    width: 100%;
    height: auto;
}

.parentContainer {
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
}

.parentContainer::-webkit-scrollbar {
    display: none;
}

.HorizontalScrollContainer {
    display: flex;
    white-space: nowrap;
    height: 180px;
    max-width: 100%;
    padding-left: 10px;
}

.HorizontalScrollContainer::-webkit-scrollbar {
    display: none;
}

.HorizontalScrollContainer video {
    width: auto;
    height: 100%;
    margin-right: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.HorizontalScrollContainer video:focus {
    outline: auto;
}

.HorizontalScrollContainer video::-webkit-media-controls-panel {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s ease;
    display: none;
}

.HorizontalScrollContainer video:hover::-webkit-media-controls-panel {
    background-color: rgba(0, 0, 0, 0.8);
}

.HorizontalScrollContainer video::-webkit-media-controls-play-button {
    background-color: transparent;
}

.HorizontalScrollContainer video::-webkit-media-controls-volume-slider {
    background-color: transparent;
}

.HorizontalScrollContainer video::-webkit-media-controls-timeline {
    background-color: rgba(255, 255, 255, 0.3);
}

.HorizontalScrollContainer video::-webkit-media-controls-timeline-container {
    height: 4px;
}

.HorizontalScrollContainer video::-webkit-media-controls-current-time-display,
.HorizontalScrollContainer video::-webkit-media-controls-time-remaining-display {
    color: white;
    font-size: 14px;
}

.HorizontalScrollContainer video::-webkit-media-controls-mute-button,
.HorizontalScrollContainer video::-webkit-media-controls-volume-slider-container {
    display: none;
}

.Home-section-content::-webkit-scrollbar {
    height: 8px;
}

.Home-section-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.Home-section-content::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}

.video-heading {
    text-align: left !important;
}

/* Responsive Styles */

@media only screen and (max-width: 600px) {
    .wrapper-chair {
        width: 90%;
        height: 200px;
        border-radius: 10px;
        padding-top: 40px;
        margin-top: 40px;
    }

    .Home-Text-Panel-container-chair {
        border-radius: 10px;
        height: 230px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Home-Text-Panel-container-chair h1 {
        font-size: 16px;
    }

    .Home-product-image {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        width: 50%;
        padding-left: 10px;
        border-radius: 20px;
    }

    .Home-product-image h2 {
        font-size: 14px;
    }

    .Home-product-image p {
        font-size: 12px;
    }

    .Home-image-view {
        height: calc(100vh - 500px);
    }
}

@media only screen and (max-width: 600px) {
    .wrapper {
        display: flex;
        align-items: center;
        width: 90%;
        height: auto;
        border-radius: 10px;
        padding-top: 120px;
        margin-top: 20px;
    }

    .text {
        flex: 1;
        /* Adjust other text styling properties as needed */
    }

    .Home-Text-Panel-container {
        border-radius: 10px;
        height: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: first baseline;
        justify-content: column;
    }

    .Home-Text-Panel-container h1 {
        font-size: 18px;
        flex-direction: center;

    }

    .Home-product-image {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        width: 90%;
        padding-left: 10px;
        border-radius: 20px;
    }

    .Home-product-image h2 {
        font-size: 14px;
    }

    .Home-product-image p {
        font-size: 12px;
    }

    .Home-image-view {
        height: auto;
        max-height: calc(100vh - 500px);
    }

    .Home-section-buttons {
        flex-direction: center;
        align-items: center;
        margin-bottom: 10px;
        align-items: center;
    }

    .Home-section-buttons button {
        font-size: 8px;
        margin-bottom: 10px;
        width: 80px;
    }

    .Home-image-view__container {
        max-width: 100%;
        max-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Home-image-view__image {
        max-width: 90%;
        max-height: 90%;
        object-fit: contain;
    }
}

/* Other responsive styles for different screen sizes */

/* Add your custom responsive styles here */

.step-container {
    padding-top: 40px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;


}

.step-number {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #333;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    background-image: linear-gradient(to right, #d7d2cc 0%, #304352 100%);
}

.step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: calc(100% - 5px);
    left: calc(50% - 1px);
    width: 4px;
    height: 30px;
    /* Adjust the length of the line */
    background-color: #E4E4E1;
    background-image: radial-gradient(at top center, rgba(255, 255, 255, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), linear-gradient(to top, rgba(255, 255, 255, 0.1) 0%, rgba(143, 152, 157, 0.60) 100%);
    background-blend-mode: normal, multiply;
}

.step-title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
}