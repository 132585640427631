.home-footer {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 20px;
    box-sizing: border-box;
}

.home-footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin: 0 auto;
    padding: 20px;
    width: 90%;
    /* Adjust the width as needed */
}

.home-footer-column {
    flex: 1 1 100%;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .home-footer-column {
        flex: 0 1 auto;
        max-width: 50%;
    }
}

.home-footer-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.home-footer-text {
    font-size: 15px;
    margin-bottom: 20px;
}

.home-footer-bottom {
    background-color: #555;
    padding: px;
    text-align: center;
}

.Homefooter-smaller-image {
    max-width: 100%;
    max-height: 300px;
    border-radius: 20px;
}

.Home-Text-Panel-container {
    display: flex;
    margin: 0px 0px 30px;
}

.home-footer {
    transition: transform 0.8s ease-in-out;
}

.home-footer.scrolled {
    transform: scale(1.1);
}