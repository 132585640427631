body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.tables-container {
    overflow-x: hidden;
}

.tables-products-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 100px;
}


.tables-product-text {
    padding: 60px;
    background-color: #333;
    color: white;
    text-align: center;
}

.tables-content-container {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
}

.tables-sidebar p {
    font-weight: bold;
}

.tables-sidebar {
    width: 100%;
    max-width: 100%;
    background-color: #f2f2f2;
    padding: 10px;
    float: left;
}

.tables-sidebar-button {
    background-color: #333;
    color: white;
    margin: 8px;
    width: max-content;
    padding: 3px;
    border-radius: 10px;
}

.tables-grid-option {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    /* Add additional styles if needed */
}

.tables-grid-option-button {
    padding: 5px 10px;
    margin-left: 5px;
    background-color: #eee;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.tables-grid-option-button.active {
    background-color: #ccc;
}



.tables-mainCategory-button {
    background-color: #333;
    color: white;
    margin: 8px;
    width: max-content;
    padding: 3px;
    border-radius: 10px;
    transition: background-color 0.3s;
}

.tables-mainCategory-button:hover,
.tables-mainCategory-button.selected {
    background-color: #ffffff;
    color: black;
    /* Adjust the hover and selected background color as desired */
}

.tables-mainCategory-button img {
    display: block;
    width: 140px;
    /* Adjust the width as needed */
    margin: 0 auto 5px;
    /* Positions the image at the bottom and adds some margin */
}

.tables-subTableCategory-button {
    background-color: #4a4a4a;
    color: white;
    margin: 8px;
    width: max-content;
    padding: 3px;
    border-radius: 10px;
    transition: background-color 0.3s;
}



.tables-subTableCategory-button:hover,
.tables-subTableCategory-button.selected {
    background-color: #ffffff;
    color: black;
    /* Adjust the hover and selected background color as desired */
}

.tables-subTableCategory-button img {
    display: block;
    width: 170px;
    /* Adjust the width as needed */
    margin: 0 auto 5px;
    /* Positions the image at the bottom and adds some margin */
}

.tables-product-list {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin: 20px;
}

.tables-product-list.grid-3 {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.tables-product-list.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.tables-product-image {
    transition: transform 0.3s;
}

.tables-product-image:hover {
    transform: scale(1.05);
}

.tables-product-image img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    border-radius: 20px;
}

.tables-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    cursor: pointer;
    backdrop-filter: blur(10px);
}

.tables-popup {
    max-width: 90%;
    max-height: 100%;
    background-color: white;
    border-radius: 8px;
    position: relative;
}

.tables-popup-image {
    max-width: 100%;
    max-height: 70vh;
    /* Adjust the value as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.tables-popup-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.tables-popup-nav {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    display: flex;
    justify-content: space-between;
}

.tables-popup-nav-button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.tables-popup-nav-button:hover {
    background-color: #555;
}


@media (max-width: 768px) {
    .tables-mainCategory-button img {
        width: 90px;
        /* Adjust the width as needed for mobile */
        margin-bottom: 2px;
        /* Reduce the margin for mobile */
    }

    .tables-popup-nav-button {
        padding: 8px 16px;
        font-size: 14px;
        background-color: #333;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .tables-subTableCategory-button img {
        width: 90px;
        /* Adjust the width as needed for mobile */
        margin-bottom: 2px;
        /* Reduce the margin for mobile */
    }

    .tables-products-page {
        margin-top: 110px;
    }

    .tables-product-list.grid-3 {
        flex-grow: 1;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }

    .tables-product-list.grid-4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
}