body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.container {
    overflow-x: hidden;
}

.products-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 100px;
}


.product-text {
    padding: 20px;
    background-color: #675656;
    color: white;
    text-align: center;
}

.content-container {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
}

.sidebar p {
    font-weight: bold;
}

.sidebar {
    width: 100%;
    max-width: 100%;
    background-color: #f2f2f2;
    padding: 10px;
    float: left;
}

.sidebar-button {
    background-color: #675656;
    color: white;
    margin: 8px;
    width: max-content;
    padding: 3px;
    border-radius: 10px;
}

.grid-option {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    /* Add additional styles if needed */
}

.grid-option-button {
    padding: 5px 10px;
    margin-left: 5px;
    background-color: #eee;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.grid-option-button.active {
    background-color: #ccc;
}

.mainCategory-button {
    background-color: #675656;
    color: white;
    margin: 8px;
    width: max-content;
    padding: 3px;
    border-radius: 10px;
    transition: background-color 0.3s;
}

.mainCategory-button:hover,
.mainCategory-button.selected {
    background-color: #ffffff;
    color: black;
    /* Adjust the hover and selected background color as desired */
}

.mainCategory-button img {
    display: block;
    width: 140px;
    /* Adjust the width as needed */
    margin: 0 auto 5px;
    /* Positions the image at the bottom and adds some margin */
}

.subTableCategory-button {
    background-color: #675656;
    color: white;
    margin: 8px;
    width: max-content;
    padding: 3px;
    border-radius: 10px;
    transition: background-color 0.3s;
}


.subTableCategory-button:hover,
.subTableCategory-button.selected {
    background-color: #ffffff;
    color: black;
    /* Adjust the hover and selected background color as desired */
}

.subTableCategory-button img {
    display: block;
    width: 170px;
    /* Adjust the width as needed */
    margin: 0 auto 5px;
    /* Positions the image at the bottom and adds some margin */
}

.product-list {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin: 20px;
}

.product-list.grid-3 {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.product-list.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.product-image {
    transition: transform 0.3s;
}

.product-image:hover {
    transform: scale(1.05);
}

.product-image img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    border-radius: 20px;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    cursor: pointer;
    backdrop-filter: blur(10px);
}

.popup {
    max-width: 90%;
    max-height: 100%;
    background-color: white;
    border-radius: 8px;
    position: relative;
}

.popup-image {
    max-width: 100%;
    max-height: 70vh;
    /* Adjust the value as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.popup-nav {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    display: flex;
    justify-content: space-between;
}

.popup-nav-button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup-nav-button:hover {
    background-color: #555;
}

.color-options {
    display: flex;
    gap: 4px;
    margin-top: 4px;
}

.color-option {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
}

.color-option.active {
    border: 2px solid #000;
}

@media (max-width: 768px) {
    .mainCategory-button img {
        width: 90px;
        /* Adjust the width as needed for mobile */
        margin-bottom: 2px;
        /* Reduce the margin for mobile */
    }

    .popup-nav-button {
        padding: 8px 16px;
        font-size: 14px;
        background-color: #333;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .subTableCategory-button img {
        width: 90px;
        /* Adjust the width as needed for mobile */
        margin-bottom: 2px;
        /* Reduce the margin for mobile */
    }

    .products-page {
        margin-top: 110px;
    }

    .product-list.grid-3 {
        flex-grow: 1;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }

    .product-list.grid-4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
}